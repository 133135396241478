/* global vscode */
/* eslint-disable no-unused-vars */
import './vscode';

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, BrowserRouter, MemoryRouter } from "react-router-dom";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import './index.css';
import { GlobalStyle } from './global-style';
import { ThemeProvider } from './uilib';
import App from './App';
import * as serviceWorkerRegistration from './service-worker-registration';

const root = ReactDOM.createRoot(document.getElementById('root'));

// const Router = vscode ? HashRouter : BrowserRouter
const Router = HashRouter

root.render(
  <React.StrictMode>
    <GlobalStyle/>
    <Router>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </DndProvider>
    </Router>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (!vscode) serviceWorkerRegistration.register();

// Signal to VS Code that the webview is initialized.
vscode?.postMessage({ type: 'ready' });

/* eslint-disable no-unused-vars */
/* global vscode */

import React, { useState, useEffect, useMemo, useRef, useCallback, forwardRef, memo, createContext, useContext, startTransition, useTransition, useDeferredValue } from "react";

import { default as at } from 'core-js-pure/features/array/at';
import { concatUint8Arrays } from 'typed-array-utils';

import { useTable, useColumnOrder, useFilters, useGlobalFilter, useSortBy } from 'react-table'
import styled from 'styled-components'
import { Link, NavLink, useLocation, useParams, useNavigate, useMatch as useRouteMatch } from "react-router-dom";
import { default as isEqual } from "react-fast-compare";
import { default as Modal } from 'react-modal';
import { StorageArea } from 'kv-storage-polyfill/src/index';
import { useVirtual } from 'react-virtual'
import { useDrag, useDrop } from 'react-dnd'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMedia, useSearchParam, useLocalStorage } from "react-use";
import { default as cx } from 'classnames';

import { range, zip2 } from './iter';
import { postMessage } from "./post-message-x";
import { usePrevious, useAsyncEffect, useContentRect, useQuery, useStorageArea } from "./hooks";
import { useResizeColumns } from "./custom-react-table/use-resize-column";
// import { scrollbarWidth } from './scroll-bar-width'

import { Button, LinkButton, Input, TextArea, Fieldset } from './uilib';

import modalStyles from './modal.module.css';

const MAX_HIST_DIST = 10;
const PER_PAGE = 100;

const handleStorage = new StorageArea('handles');
const fileCacheStorage = new StorageArea('files');

Modal.setAppElement('#root');

export const KEY_SEP = '‖';

const ModalStyles = styled.div`
  .title-bar {
    display: flex; 
    align-items: center; 
    border-bottom: 1px solid var(--lightgray);
    background: var(--title-background);
    font-weight: bold;

    ${props => vscode ? `
      padding: 5px 10px;
    ` : `
      padding: 1px;
      height: ${props.hh}px;
      color: var(--background);
    `}

    a, button.as-link {
      ${props => vscode ? `` : `
        height: ${props.hh - 6}px;
        width: ${props.hh - 6}px;
        line-height: ${props.hh - 6}px;
        margin: 3px 0 3px 3px;
        padding: 0;
        font-weight: bold;
      `}
    }
  }

  .fluid-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(350px, 100%), 1fr));
    // gap: 1rem;
  }

  fieldset {
    user-select: text;
  }
  fieldset label {
    display: block;
    margin-bottom: 5px;
  }
  fieldset input, fieldset textarea {
    width: 100%;
    margin-bottom: 2px;
  }
`

const Styles = styled.div`
  .fluid-grid {
    display: grid;
    gap: 1rem;
  }

  .table {
    border-spacing: 0;

    .tr {
      position: relative;
      width: 100%;
      display: flex;
      &.virtual {
        position: absolute;
        top: 0;
        left: 0;
        background: var(--even-row-background);
        &.odd {
          background: var(--odd-row-background);
        }
      }
      &.fixed {
        position: sticky;
        background: var(--thead-background);
        z-index: 2;
      }
    }
    .th {
      border-right: 1px solid var(--lightgray);
      // border-bottom: 1px solid var(--lightgray);
      i { color: var(--vscode-list-deemphasizedForeground) }

      .default-column-filter {
        margin: 0;
        width: 50px; 
        flex: 1 1 50px;
        height: 22px;
        font-size: smaller;
      }
    }

    .td {
      height: ${props => props.hh}px;
      line-height: ${props => props.hh - 2}px;
      padding: 0px 8px;

      &.active {
        position: relative;
      }
      &.active::before {
        content: '';
        position: absolute;
        top: -2px; left: -2px; right: -2px; bottom: -2px;
        border: 2px solid var(--purple);
        z-index: 9;
      }

      &.fixed {
        position: sticky;
        z-index: 2;
      }
      &.virtual {
        position: absolute;
        top: 0;
        left: 0;
        will-change: transform;
      }
      &.right {
        text-align: right;
      }

      button {
        width: ${props => props.hh - 4}px;
        height: ${props => props.hh - 4}px;
        padding: 0 2px;
      }
    }

    .th, .td {
      margin: 0;
      border-right: 1px solid var(--lightgray);
      // border-bottom: 1px solid var(--lightgray);

      .resizer {
        background: transparent;
        width: 12px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 8;
        touch-action: none;
        &.isResizing {
          width: 4px;
          background: var(--drag-border);
        }
      }
    }

    .tr > .td:first-child {
      text-align: right;
    }

    .td.fixed {
      background: var(--thead-background);
    }

    .tr:not(:first-child) {
      a.open-modal { padding: 0 4px }
      .hidden-button { display: block; opacity: 0; width: 0; float: left }
      .hidden-button:focus { opacity: 1; width: unset }
      &:hover { 
        > .td { background: linear-gradient(var(--row-hover-background), var(--row-hover-background)), var(--background); }
        .hidden-button { opacity: 1; width: unset }
      }
    }
    .tr:last-child {
      border-bottom: 1px solid var(--lightgray);
    }

    header .tr {
      > .th:first-child {
        background-color: var(--thead-background);
      }
    }

    .sticky {
      position: sticky!important;
      z-index: 2;
    }

    .null {
      color: var(--null);
    }

    input[type=checkbox] {
      display: none;
    }

    input[type=checkbox] + label {
      border: 1px solid var(--gray);
      padding: 1px 2px;
      border-radius: 3px;
    }

    input[type=checkbox]:checked + label {
      padding-top: 2px;
      padding-left: 3px;
      padding-right: 1px;
      padding-bottom: 0px;
      background-color: var(--gray3)
    }
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--lightgray);
    height: ${props => props.hh}px;
    background: var(--thead-background);
    button {
      width: ${props => props.hh - 1}px;
      height: ${props => props.hh - 1}px;
      padding: 0 2px;
    }
  }

  .contain {
    contain: content;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .muted {
    color: var(--muted);
  }

  .header-bar {
    height: calc(env(titlebar-area-height, ${props => props.hh}px) + 1px);
    background-color: var(--gray6);
    display: flex; 
    align-items: center; 
    justify-content: space-between; 
    padding: 0 8px; 
    /* border-top: 1px solid var(--lightgray); */
    border-bottom: 1px solid var(--lightgray); 
    font-weight: bold; 
    -webkit-app-region: drag;
    app-region: drag;
  }
`

export const affinityEmoji = new Map(Object.entries(vscode
  ? {
    INTEGER: <i className="codicon codicon-symbol-number" />,
    NUMERIC: <i className="codicon codicon-symbol-numeric" />,
    REAL: <i className="codicon codicon-symbol-numeric" />,
    TEXT: <i className="codicon codicon-symbol-string" />,
    BOOLEAN: <i className="codicon codicon-symbol-boolean" />,
    DATE: <i className="codicon codicon-calendar" />,
    BLOB: <i className="codicon codicon-file-binary" />,
    '?': <i className="codicon codicon-question" />,
  } : {
    INTEGER: '🔢',
    NUMERIC: '🔢',
    REAL: '🔢',
    TEXT: '🔤',
    BOOLEAN: '🔟',
    DATE: '📅',
    BLOB: '🗄️',
    '?': '❓',
  }))

async function askPermission(fileHandle, mode = 'read') {
  const options = {};
  if (mode) {
    options.mode = mode;
  }
  // Check if permission was already granted. If so, return true.
  if ((await fileHandle.queryPermission(options)) === 'granted') {
    return true;
  }
  // Request permission. If the user grants permission, return true.
  if ((await fileHandle.requestPermission(options)) === 'granted') {
    return true;
  }
  // The user didn't grant permission, so return false.
  return false;
}

export class SampleHandle {
  constructor(file) {
    this.sample = true;
    this.file = file
  }
  async getFile() {
    return this.file;
  }
  async queryPermission() {
    return 'granted';
  }
  async requestPermission() {
    return 'granted';
  }
  get name() { return this.file.name }
}

export async function getFromHandleStorage(filename) {
  const { handle } = await handleStorage.get(filename) ?? {}
  if (handle?.sample) return new SampleHandle(handle.file);
  return handle;
}

/** @param {number|bigint} bytes */
export function formatBytes(bytes, decimals = 2) {
  bytes = Number(bytes) // coerce to number 

  if (bytes === 0) return /** @type {const} */([0, 'Bytes']);

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return /** @type {const} */([parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), sizes[i]]);
}

function FormatBytes({ value, ...args }) {
  return <span {...args}>{formatBytes(value).join(' ')}</span>
}

const fileRegExp = /(?<basename>.*)(?<extname>\.[^.]+)$/;
const basename = _ => fileRegExp.exec(_)?.groups.basename ?? '';

function DownloadButton({ worker, filename, name, columnId, rowId, text, ...props }) {
  const [objectURL, setObjectURL] = useState(null)
  const download = `${basename(filename)}-${name}-${rowId}-${columnId}`;
  const onClick = useCallback(async _ => {
    const u8a = await postMessage(worker, { id: 'execOne', query: `SELECT ${columnId} FROM [${name}] WHERE "_rowid_"=${rowId}` }) // FIXME: non rowid tables
    if (vscode) {
      vscode.postMessage({
        type: 'blob',
        data: u8a,
        download,
        metaKey: _.metaKey,
      }, [u8a.buffer]);
    } else {
      const file = new File([u8a.buffer], { type: 'application/octet-stream' });
      const objectURL = URL.createObjectURL(file);
      setObjectURL(objectURL)
    }
  }, [columnId, download, name, rowId, worker])

  const aRef = useRef(null);
  useEffect(() => {
    if (objectURL && aRef.current) {
      aRef.current.click()
      setObjectURL(null);
      URL.revokeObjectURL(objectURL)
    }
  }, [objectURL])

  return <>
    {objectURL
      ? <a ref={aRef} href={objectURL} download={download} /> // eslint-disable-line jsx-a11y/anchor-has-content
      : <Button title="Download" {...props} onClick={onClick}>{vscode ? <i className="codicon codicon-desktop-download" /> : '💾'}{text ? ' ' : ''}{text}</Button>}
  </>
}

const getPKNamesOrd = schema => schema.subRows.filter(_ => _.pk).sort((a, b) => a.pk - b.pk).map(_ => `[${_.name}]`)

function mkSelection(schema, { textCutoff = 1_024 } = {}) {
  // TODO: better way to determine if is rowid table??
  const hasRowId = !/WITHOUT\s+ROWID/i.test(schema.sql)
  const sel = [
    hasRowId
      ? `"_rowid_" AS _rowid_`
      : `${getPKNamesOrd(schema).join(` || "${KEY_SEP}" || `)} AS _rowid_`,
    ...schema.subRows.map(_ => {
      return _.type === 'TEXT'
        ? `substr([${_.name}],0,${textCutoff}) AS [${_.name}]`
        : _.type === 'BLOB'
          ? `length([${_.name}]) AS [${_.name}]` // TODO
          : `[${_.name}]`;
    }),
  ];
  return sel;
}

function assembleWhereClause(schema, idStr) {
  // TODO: better way to determine if is rowid table??
  if (!idStr) return null;
  const hasRowId = !/WITHOUT\s+ROWID/i.test(schema.sql)
  const vals = idStr.split(KEY_SEP)
  // TODO: save bindings..
  return hasRowId
    ? `WHERE "_rowid_"="${vals[0]}"`
    : `WHERE ${getPKNamesOrd(schema).map((name, i) => `${name}="${vals[i]}"`).join(' AND ')}`
}

function assembleWhereClauseMulti(schema, ids) {
  if (!ids.length) return null;
  const hasRowId = !/WITHOUT\s+ROWID/i.test(schema.sql)
  if (hasRowId) {
    return `WHERE "_rowid_" IN (${ids.join(',')})`
  } else {
    const idTuples = ids.map(_ => _.split(KEY_SEP))
    const mkThing = vals => getPKNamesOrd(schema).map((name, i) => `${name}="${vals[i]}"`).join(' AND ')
    return `WHERE ${idTuples.map(vals => `(${mkThing(vals)})`).join(' OR ')}`;
  }
}

// function fixupResult(data) {
//   const rowId = data.columns[0];
//   data.objectValues.forEach(_ => { _._rowid_ = _[rowId]; })
//   data.columns[0] = '_rowid_'
//   return data;
// }

export function KeySymbols({ pk, maxPk, fk, fkTable, fkName }) {
  return <>
    {pk
      ? <span title={`Primary Key (${pk}/${maxPk})`}>{
        vscode
          ? <i className="codicon codicon-key" style={{ color: 'var(--vscode-symbolIcon-classForeground)' }} />
          : '🔑'}</span>
      : ''}
    {' '}
    {fk
      ? <span title={`Foreign Key\n${[...zip2(fkTable.split(KEY_SEP), fkName.split(KEY_SEP))].map(_ => `→ ` + _.join(' / ')).join('\n')}`}>
        {vscode
          ? <i className="codicon codicon-key" style={{ color: 'var(--vscode-symbolIcon-keyForeground)' }} />
          : '🗝️'
        }
      </span>
      : ''}
  </>
}

const encodeId = _ => _ && encodeURIComponent(_);

const toRoman = (num) => {
  if (num >= 1000) return "M" + toRoman(num - 1000)
  else if (num >= 900) return "CM" + toRoman(num - 900)
  else if (num >= 500) return "D" + toRoman(num - 500)
  else if (num >= 400) return "CD" + toRoman(num - 400)
  else if (num >= 100) return "C" + toRoman(num - 100)
  else if (num >= 90) return "XC" + toRoman(num - 90)
  else if (num >= 50) return "L" + toRoman(num - 50)
  else if (num >= 40) return "XL" + toRoman(num - 40)
  else if (num >= 10) return "X" + toRoman(num - 10)
  else if (num === 9) return "IX" + toRoman(num - 9)
  else if (num >= 5) return "V" + toRoman(num - 5)
  else if (num === 4) return "IV" + toRoman(num - 4)
  else if (num >= 1) return "I" + toRoman(num - 1)
  return ''
}

export function ExploreView({ worker, workerId, tableGroups, handle, error, askPermissionCallback, hh, filename, type, name, openFile, loadSample, recentlyOpened, isApp }) {
  const [_data, setData] = useState({ columns: [], objectValues: [], offset: 0, name })
  const [count, setCount] = useState(0);

  const [querySuffix, setQuerySuffix] = useState('')
  const [offset, setOffset] = useState(0)

  const schema = useMemo(() => tableGroups[['table', 'index', 'view', 'trigger'].indexOf(type)]?.subRows.find(_ => _.name === name), [type, name, tableGroups])
  const schemaData = useMemo(() => schema?.subRows ?? [], [schema])
  const empty = useMemo(() => ({ objectValues: [], columns: ['_rowid_', ...schemaData.map(_ => _.name)] }), [schemaData])

  const [fixedRows, setFixedRows] = useState([]);
  const fixedRowIds = useMemo(() => fixedRows.map(_ => _._rowid_), [fixedRows])
  const toggleFixedRows = useCallback(row => {
    const id = row._rowid_;
    setFixedRows(fixedRowIds.includes(id)
      ? fixedRows.filter(_ => _._rowid_ !== id)
      : fixedRows.concat(row))
  }, [fixedRows, fixedRowIds])

  useEffect(() => setFixedRows([]), [name])

  const __data = useMemo(() => ({ ..._data, objectValues: fixedRows.concat(_data.objectValues) }), [fixedRows, _data])
  const data = useDeferredValue(() => __data)

  useAsyncEffect(async ({ signal }) => {
    if (worker && schema) {
      try {
        const count = await postMessage(worker, { id: 'execOne', query: `SELECT COUNT(*) FROM [${name}] ${querySuffix}` }, { signal })
        setCount(count);

        const selection = mkSelection(schema)
        const queryLimit = `LIMIT ${3 * PER_PAGE} OFFSET ${offset}`
        const pageQuery = `SELECT ${selection.join()} FROM [${name}] ${querySuffix} ${queryLimit}`;

        const data = await (count > 0
          ? postMessage(worker, { id: 'execAsObject', query: pageQuery, bigInt: true }, { signal })
          : empty);

        data.offset = offset;
        data.table = name;

        setData(data);
      } catch (_) {
        // TODO
        throw _
      }
    } else {
      setData(empty);
    }
  }, [worker, workerId, type, name, querySuffix, schema, offset, empty])

  const columns = useMemo(() => [
    {
      id: '__row/index@@~',
      Header: '',
      accessor: (_, i) => i + data.offset + 1 - fixedRowIds.length,
      disableFilters: true,
      noDragAndDrop: true,
      width: 85,
      headerStyle: {
        position: 'sticky',
      },
      Cell: ({ value, row, rowFixed, fixedRowIndex, toggleFixedRows }) => {
        return <>
          <Link className="hidden-button open-modal"
            tabIndex={1}
            to={`${filename}/${type}/${name}/${encodeId(row.id)}/`}
            state={rowFixed ? {} : { index: value, distance: 1, backId: '__xx__$$@--' }} // HACK
          >
            {vscode ? <i className="codicon codicon-arrow-up rotate-45" /> : '↗'}
          </Link>
          <Button
            className="as-link hidden-button"
            title={rowFixed ? 'Unpin row' : 'Pin row'}
            style={{ padding: '0 2px', background: 'none', boxShadow: 'none' }}
            onClick={e => { e.stopPropagation(); toggleFixedRows({ ...row.original }) }}
          >
            {vscode ? <i className={cx('codicon', rowFixed ? 'codicon-pinned' : 'codicon-pin')} /> : '📌'}
          </Button>
          {rowFixed ? toRoman(fixedRowIndex + 1) : value}
        </>
      },
      cellDeps: [filename, type, name], // HACK
    },
    ...data.columns?.slice(1)
      .map(_ => schemaData?.find(_2 => _2.name === _) ?? { name: _ })
      .map(({ name: colName, pk, maxPk, fk, fkTable, fkName, type: schemaType, typeAffinity }) => ({
        id: colName,
        schemaInfo: { pk, maxPk, fk, fkTable, fkName, type: schemaType, typeAffinity },
        Header: ({ column, fixed, toggleFixed, colIndex }) => {
          // const sub = type?.match(/\(([\d,]+)\)/)
          // useEffect(() => { console.log('hello') }, []);
          return <div style={{ display: 'flex', height: hh, alignItems: 'center', justifyContent: 'stretch' }}>
            <span title={colName} className="ellipsis" style={{ flex: '1' }}>
              {colName}
            </span>
            <span style={{ flex: '10px 0 1', textAlign: 'right' }}>
              {' '}
              {!column.isSorted
                ? ''
                : !column.isSortedDesc
                  ? <>{vscode ? <i className="codicon codicon-triangle-up" style={{ color: 'var(--color)' }} /> : '▲'}<small><sup>{column.sortedIndex + 1}</sup></small></>
                  : <>{vscode ? <i className="codicon codicon-triangle-down" style={{ color: 'var(--color)' }} /> : '▼'}<small><sup>{column.sortedIndex + 1}</sup></small></>}
              <KeySymbols pk={pk} maxPk={maxPk} fk={fk} fkTable={fkTable} fkName={fkName} />
              {' '}
              <span title={schemaType || 'Unknown'}>
                {affinityEmoji.get(typeAffinity)}
              </span>
              <Button title={fixed ? 'Unpin column' : 'Pin column'}
                className="as-link"
                style={{ padding: '0 2px', boxShadow: 'none', background: 'none' }}
                onClick={e => { e.stopPropagation(); toggleFixed(column.id, colIndex) }}
              >
                {vscode ? <i className={cx('codicon', fixed ? 'codicon-pinned' : 'codicon-pin')} /> : '📌'}
              </Button>
            </span>
          </div>;
        },
        accessor: colName,
        isAlignRight: value => ['INTEGER', 'REAL', 'NUMERIC', 'BLOB'].includes(typeAffinity) || value instanceof Uint8Array,
        width: schemaType === 'TEXT' || schemaType === 'LONGVARCHAR'
          ? 150
          : schemaType === 'BOOLEAN'
            ? 50
            : 150,
        // disableSortBy: typeAffinity === 'BLOB' ? true : false,
        Cell: ({ value, column, row }) => {
          return value == null
            ? <code className="null">NULL</code>
            : typeAffinity === 'BLOB' || value instanceof Uint8Array
              ? <>
                <FormatBytes value={value?.byteLength ?? value} />
                {' '}
                <DownloadButton style={vscode ? { marginRight: -8 } : {}} worker={worker} filename={filename} name={name} columnId={column.id} rowId={row.id} />
              </>
              : typeAffinity === 'BOOLEAN'
                ? <code>{value ? 'TRUE' : 'FALSE'}</code>
                : ['INTEGER', 'REAL', 'NUMERIC'].includes(typeAffinity)
                  ? <code>{value?.toString()}</code>
                  : value?.toString()
        },
      })),
    {
      // TODO: buffer..
      id: '__row/buffer@@~',
      Header: '',
      accessor: () => '',
      disableResizing: true,
      disableFilters: true,
      disableSortBy: true,
      noDragAndDrop: true,
      width: 100,
    },
    // NOTE: Only updating schema when data changed _on purpose_ to avoid inconsistent state...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [data, hh, worker])

  // console.log(tableGroups)
  // const target = useRef(null)
  // const size = useSize(target)

  // const rectRef = useRef(null);
  // const { width, height } = useContentRect(rectRef) ?? {};

  const [activeRow, setActiveRow] = useState(null);

  const standalone = useMedia('(display-mode:standalone)')
  const [isFs, setIsFs] = useStorageArea('fs', standalone)
  const goFullscreen = useCallback(() => {
    document.body.classList.toggle('fs', !isFs);
    setIsFs(!isFs)
  }, [isFs, setIsFs])

  return <Styles hh={hh} style={{ height: '100%' }}>
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {vscode ? null : <div className={cx({ 'header-bar': true, 'inactive': activeRow })} >
        <span className="ellipsis" style={{ flex: 1 }}>
          {filename}
          {filename && <span style={{ color: 'var(--muted)' }}>{' ▶ '}</span>}
          {{ table: '🗓️', index: '🏷️', view: '🌁', trigger: '📜' }[type] || ''}
          {' '}
          {name}
        </span>
        {/* <span className="ellipsis" style={{ fontWeight: 'normal', marginLeft: 10 }}></span> */}
        {!standalone && <Button onClick={goFullscreen} style={{ padding: '4px 6px', marginLeft: 10, marginRight: -5 }}>
          {isFs ? '↙' : '↗'}
        </Button>}
      </div>}
      <div style={{ flex: 1, overflow: 'hidden' }}>
        {handle || error || !filename
          ? <div className="center">
            <div style={{ textAlign: 'center' }}>
              {error
                ? <>
                  <div>An error occurred: <code>{error.message}</code>.</div>
                </>
                : handle
                  ? <>
                    <Button onClick={askPermissionCallback}>{'⚠️'} Resume Viewing <code>{handle.name}</code></Button>
                    <div>SQL Viewer Lite needs you permission to resume viewing <code>{handle.name}</code>.</div>
                  </>
                  : !filename && !vscode
                    ? <>
                      <div style={{ marginBottom: 5 }}>Open or drop a <code>.sqlite</code> file. You can also view a sample:</div>
                      <Button className="ellipsis" onClick={openFile} title="Open File" style={{ marginRight: 5 }}>{'📁'} Open File</Button>
                      <LoadSampleButton loadSample={loadSample} />
                      {recentlyOpened.length ? <div>
                        <h3 style={{ marginTop: '3rem' }}>Recently Opened</h3>
                        {/* HACK */}
                        <div className="fluid-grid" style={{ width: `calc(100vw * 0.33)`, gridTemplateColumns: `repeat(auto-fit, minmax(250px, 1fr))` }}>
                          {recentlyOpened.map(_ => <Link key={_} to={`${_}/`}>{_}</Link>)}
                        </div>
                      </div> : null}
                    </>
                    : null}
            </div>
          </div>
          : data.columns?.length > 1
            ? (
              <EndlessTable
                worker={worker}
                schema={schema}
                columnsData={columns}
                count={count}
                data={data.objectValues}
                dataTable={data.table}
                offset={data.offset}
                setSuffix={setQuerySuffix}
                setOffset={setOffset}
                hh={hh}
                type={type}
                name={name}
                activeRow={activeRow}
                setActiveRow={setActiveRow}
                toggleFixedRows={toggleFixedRows}
                fixedRowIds={fixedRowIds}
                isApp={isApp}
              />
            )
            : null
        }
      </div>
    </div>
  </Styles>
}

function LoadSampleButton({ loadSample }) {
  const [loadingSample, setLoadingSample] = useState(false);
  const [percent, setPercent] = useState()

  const wrappedLoadSample = useCallback(async () => {
    setLoadingSample(true)
    setPercent('0')
    const response = await fetch(document.getElementById('sample-sqlite').href)
    if (response.ok && response.headers.has('content-length')) {
      const contentLength = Number(response.headers.get('content-length'));
      const reader = response.body.getReader()
      const buffers = [];
      let bytesReceived = 0;
      while (true) {
        const result = await reader.read();
        if (result.done) {
          loadSample(concatUint8Arrays(...buffers))
          break;
        }
        buffers.push(result.value);
        bytesReceived += result.value.length;
        setPercent((bytesReceived / contentLength * 100).toFixed(0))
      }
    } else {
      setPercent('Error')
    }
  }, [loadSample])

  return (
    <Button className="ellipsis" onClick={wrappedLoadSample} title="Load a sample" disabled={loadingSample} style={{ marginRight: 0 }}>
      {loadingSample ? `⚙️ Loading ${percent}%…` : '📦 Load a sample'}
    </Button>
  )
}

// const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => ({
//   ...draggableStyle,
//   // some basic styles to make the items look a bit nicer
//   userSelect: "none",

//   // change background colour if dragging
//   background: isDragging ? "var(--lightgray)" : "var(--gray)",

//   ...(!isDragging && { transform: "translate(0,0)" }),
//   ...(isDropAnimating && { transitionDuration: "0.001s" })

//   // styles we need to apply on draggables
// });

const CheckboxButton = ({ id, title, checked, onChange, inputProps, labelProps, children }) => {
  return <>
    <input type="checkbox" id={id} checked={checked} onChange={onChange} {...inputProps} />
    <label htmlFor={id} title={title} {...labelProps}>{children}</label>
  </>
}

function DefaultColumnFilter({
  column: { id, filterValue, setFilter },
  // state: { columnResizing: { isResizingColumn } },
}) {
  const { value, exact, invert } = filterValue ?? { value: '', exact: false, invert: false }
  const updateFilter = (value, exact, invert) => {
    setFilter(value || exact || invert ? { value, exact, invert } : undefined)
  }

  // const onChange1 = _ => updateFilter(value, _.target.checked, invert)
  // const onChange2 = _ => updateFilter(value, exact, _.target.checked)

  return <div style={{ display: 'flex' }}>
    <Input
      className="default-column-filter"
      value={value || ''}
      onChange={_ => updateFilter(_.target.value, exact, invert)}
      placeholder={`Search column…`}
    // disabled={isResizingColumn != null}
    />
    {/* <CheckboxButton id={`${id}/exact`} checked={exact} onChange={onChange1} title="Exact">{'💯'}</CheckboxButton> */}
    {/* <CheckboxButton id={`${id}/invert`} checked={invert} onChange={onChange2} title="Invert">{'❗️'}</CheckboxButton> */}
  </div>
}

const RenderCell = ({ cell, rowIndex, colIndex, width, height, start, isResizingColumn, fixed, rowFixed, toggleFixedRows, lastFixed, fixedRowIndex }) => {
  // const [selected, setSel] = useState(false)
  const selected = false, setSel = () => { };

  const baseCellProps = useMemo(() => ({
    className: cx('td', {
      active: selected,
      contain: !selected,
      ellipsis: !selected,
      fixed,
      virtual: !fixed,
      right: cell.column.isAlignRight?.(cell.value),
    }),
    style: {
      width,
      ...fixed ? {
        left: start,
      } : {
        transform: `translateX(${start}px)`,
      },
      // borderBottomColor: rowIndex % PER_PAGE === PER_PAGE - 1 ? 'var(--color)' : 'var(--lightgray)',
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [selected, fixed, cell.column.isAlignRight, cell.value, width, start])

  const cellProps = cell.getCellProps(baseCellProps)

  // NOTE: only updating cell content when value changes..
  const cellContent = useMemo(
    () => cell.render('Cell', { rowFixed, toggleFixedRows, fixedRowIndex }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cell.value, rowFixed, fixedRowIndex, toggleFixedRows, ...cell.column?.cellDeps ?? []]
  ) // HACK

  return <div {...cellProps} title={cell.value instanceof Uint8Array ? cell.value.byteLength : cell.value} onClick={_ => setSel(true)}>
    {selected
      ? <div className="contain ellipsis">{cellContent}</div>
      : cellContent}
  </div>
}

function TableHeaderCell({ column, columnOrder, index, setIsDragging, start, size, fixed, toggleFixed }) {
  const { setColumnOrder, hhh } = useContext(HeaderContext)

  const [, dragRef] = useDrag(() => ({
    type: 'column-header',
    item: () => ({
      sIndex: index,
      draggableId: column.id,
      colOrder: [...columnOrder],
    }),
    end: () => {
      setIsDragging(false)
    }
  }), [index, columnOrder])

  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: 'column-header',
    drop({ sIndex, draggableId }) {
      const co = [...columnOrder];
      co.splice(sIndex, 1);
      co.splice(index, 0, draggableId);
      setColumnOrder(co)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }), [index, columnOrder])

  return <div ref={column.noDragAndDrop ? null : dropRef} {...column.getHeaderProps({
    className: cx('th', { fixed }),
    style: useMemo(() => ({
      width: size,
      height: hhh,
      borderBottom: `1px solid var(--lightgray)`,
      top: 0,
      ...fixed ? {
        position: 'sticky',
        left: start,
      } : {
        position: 'absolute',
        left: 0,
        transform: `translateX(${start}px)`,
        willChange: 'transform',
      },
      zIndex: columnOrder.length + 1 - index,
      ...column.headerStyle
    }), [column.headerStyle, columnOrder.length, fixed, hhh, index, size, start])
  })}>
    <div
      ref={column.noDragAndDrop ? null : dragRef}
      onDragStart={() => setIsDragging(true)}
      style={{ padding: '0 8px', height: '100%', background: isOver ? 'var(--row-hover-background)' : 'var(--thead-background)' }}
    >
      <div className="ellipsis" {...column.getSortByToggleProps()}>
        {column.render('Header', { fixed, toggleFixed, colIndex: index })}
      </div>
      <div className="ellipsis">
        {column.canFilter ? column.render('Filter') : null}
      </div>
      {/* <RenderText a={1} b={2}/> */}
    </div>
    {column.getResizerProps
      ? <div {...column.getResizerProps()} className={cx('resizer', { isResizing: column.isResizing })} />
      : null}
  </div>
}

function TableHeaderRow({ setIsDragging, fixedIndices, toggleFixed }) {
  const { headerGroups, columnOrder, hhh } = useContext(HeaderContext)

  const flatHeaders = headerGroups[0].headers;

  // DRY
  const [fixedCells, fixedIds] = useMemo(() => {
    const fixedCells = range(0, fixedIndices).map(i => flatHeaders[i])
    const fixedIds = new Set(fixedCells.map(_ => _.id))
    return [fixedCells, fixedIds]
  }, [flatHeaders, fixedIndices])

  return (
    <header className="sticky contain" style={{ top: 0, left: 0, width: "100%", height: hhh, background: 'var(--thead-background)', zIndex: 3 }}>
      {headerGroups.map(headerGroup => (
        <div key={0} className="tr" {...headerGroup.getHeaderGroupProps({ style: { width: '100%', height: hhh, display: 'flex' } })}>
          {fixedCells.map((_, index) => (
            <TableHeaderCell
              key={_.id}
              column={_}
              columnOrder={columnOrder}
              index={index}
              size={_.totalWidth}
              start={_.totalLeft}
              setIsDragging={setIsDragging}
              toggleFixed={toggleFixed}
              fixed
            />
          ))}
          {flatHeaders.filter(_ => !fixedIds.has(_.id)).map((_, index) => (
            <TableHeaderCell
              key={_.id}
              column={_}
              columnOrder={columnOrder}
              index={index + fixedCells.length}
              size={_.totalWidth}
              start={_.totalLeft}
              setIsDragging={setIsDragging}
              toggleFixed={toggleFixed}
            />
          ))}
        </div>
      ))}
    </header>
  )
}

const HeaderContext = createContext('header');

// Define a default UI for filtering
const GlobalFilter = forwardRef(({
  count,
  globalFilter,
  setGlobalFilter,
}, ref) => (
  <Input
    ref={ref}
    className="ellipsis"
    value={globalFilter ?? ''}
    onChange={e => { setGlobalFilter(e.target.value) }}
    placeholder={`Search ${count} records…`}
  />
))

const Pagination = memo(({ count, page, onClick, goTo, hh, isApp }) => {
  const goToRef = useRef(null);
  useEffect(() => {
    if (goToRef.current) goToRef.current.value = page;
  }, [page])

  const totalPages = Math.max(1, Math.ceil(count / PER_PAGE));
  return <div className="pagination">
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <Button disabled={page === 1} onClick={goTo(1)}>{vscode ? <i className="codicon codicon-run-all flip-x" /> : '⏮️'}</Button>
      <Button disabled={page === 1} onClick={goTo(page - 1)}>{vscode ? <i className="codicon codicon-play flip-x" /> : '◀️'}</Button>
      <Input
        ref={goToRef}
        type="number"
        min={1}
        step={1}
        max={totalPages}
        defaultValue={page}
        onKeyPress={_ => _.key === 'Enter' ? onClick(_) : null}
        style={{ width: 75 }}
      />
      <Button disabled={page === totalPages} onClick={goTo(page + 1)}>{vscode ? <i className="codicon codicon-play" /> : '▶️'}</Button>
      <Button disabled={page === totalPages} onClick={goTo(totalPages)}>{vscode ? <i className="codicon codicon-run-all" /> : '⏭️'}</Button>
      <div className="ellipsis" style={{ marginLeft: 5, marginRight: 5 }}>Page {page} / {totalPages}</div>
    </span>
    <span className="ellipsis" style={{ marginRight: 5 }}>{vscode 
      ? <a href="https://sqliteviewer.app?ref=vscode">Try SQLite Viewer Web ↗</a> 
      : <a href="https://hydejack.com?ref=sqliteviewer.app" target="_blank">Try Hydejack, a Jekyll theme for hackers, nerds, and academics ↗</a>}
    </span>
  </div>
}, isEqual)

const defaultColumn = {
  minWidth: 30,
  width: 150,
  Filter: DefaultColumnFilter,
};

function EndlessTable({
  worker,
  schema,
  columnsData,
  count,
  data,
  setSuffix,
  offset,
  setOffset,
  type,
  name,
  dataTable,
  activeRow,
  setActiveRow,
  hh = 26,
  fixedRowIds,
  toggleFixedRows,
  isApp,
}) {
  const [page, setPage] = useState(1);

  // const { type = '', name = '' } = useParams();
  // console.log(type, name)
  const _url = dataTable;
  const _prevUrl = usePrevious(dataTable);
  const _sameUrl = _url === _prevUrl;

  // const listRef = useRef(null);
  // const innerRef = useRef(null);
  // const outerRef = useRef(null);

  const navigate = useNavigate();
  const { pathname, state: hState } = useLocation();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
    state,
    setGlobalFilter,
    resetResizing,
    setAllFilters,
    setSortBy,
    setColumnOrder,
    dispatch,
    columns,
    flatHeaders,
  } = useTable(
    {
      columns: columnsData,
      data,
      defaultColumn,
      manualSortBy: _sameUrl,
      manualFilters: _sameUrl,
      manualGlobalFilter: _sameUrl,
      autoResetResize: !_sameUrl,
      autoResetGlobalFilter: !_sameUrl,
      autoResetHiddenColumns: !_sameUrl,
      getRowId: _ => _._rowid_,
    },
    useColumnOrder,
    useResizeColumns,
    useFilters,
    useGlobalFilter,
    useSortBy,
  )

  // rows = useDeferredValue(rows)
  // columns = useDeferredValue(rows)

  const {
    sortBy = [],
    filters = [],
    globalFilter = '',
    columnResizing: { columnWidths = [], isResizingColumn = false },
    columnOrder: maybeColumnOrder,
  } = state;

  const columnsById = useMemo(() => Object.fromEntries(columns.map(_ => [_.id, _])), [columns]);
  const columnOrder = useMemo(() => maybeColumnOrder.length > 0 ? maybeColumnOrder : flatHeaders.map(_ => _.id), [maybeColumnOrder, flatHeaders])
  const fixedRows = useMemo(() => rows?.slice(0, fixedRowIds.length) ?? [], [rows, fixedRowIds])

  // TODO: make configurable
  // const [fixedIndices, setFixedIndices] = useState([columns[0].id])
  const [fixedIndices, setFixedIndices] = useState(1)

  const toggleFixed = useCallback((id, index) => {
    // setFixedIndices(fixedIndices.includes(id)
    //   ? fixedIndices.filter(_ => _ !== id)
    //   : fixedIndices.concat(id))
    const co = [...columnOrder];
    co.splice(index, 1);
    if (index >= fixedIndices) {
      co.splice(fixedIndices, 0, id);
      setFixedIndices(fixedIndices + 1)
    } else {
      co.splice(fixedIndices - 1, 0, id);
      setFixedIndices(fixedIndices - 1)
    }
    setColumnOrder(co)
  }, [columnOrder, fixedIndices, setColumnOrder])

  const resetFilters = useCallback(() => {
    setAllFilters([]);
    setGlobalFilter('');
    setSortBy([]);
  }, [setAllFilters, setGlobalFilter, setSortBy])

  // HACK
  useEffect(() => {
    resetResizing();
    dispatch({ type: 'resetColumnOrder' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTable])


  useEffect(() => {
    const q = [];

    if (filters?.length > 0 || globalFilter) {
      const wq = [];
      if (filters?.length > 0) {
        wq.push(`${filters.map(_ => {
          const { value, exact, invert } = _.value;
          return `"${_.id}" ${invert ? 'NOT' : ''} LIKE '${exact ? value : `%${value}%`}' ESCAPE '\\'`
        }).join(' AND ')}`);
      }
      if (globalFilter) {
        const fColumns = columns?.slice(1, columns.length - 1) ?? [];
        if (fColumns.length > 0) {
          wq.push(`(${fColumns.map(_ => `"${_.id}" LIKE '%${globalFilter}%' ESCAPE '\\'`).join(' OR ')})`)
        }
      }
      if (wq.length > 0) {
        q.push(`WHERE ${wq.join(' AND ')}`)
      }
    }

    if (sortBy?.length > 0) {
      q.push(`ORDER BY ${sortBy.map(_ => `"${_.id}" ${_.desc ? 'DESC' : 'ASC'}`).join(', ')}`);
    }

    // console.log(q)
    setSuffix(q.join(' '));
  }, [sortBy, filters, columns, globalFilter, setSuffix])

  useEffect(() => {
    const newOffset = Math.max(0, (page - 2) * PER_PAGE);
    setOffset(newOffset);
  }, [page, setOffset])

  const hhh = 2 * hh;

  const match = useRouteMatch('/:filename/:type/:name/:modalId/')?.params;
  useAsyncEffect(async ({ signal }) => {
    if (match) {
      const { name, modalId: mid } = match
      const modalId = decodeURIComponent(mid);
      const selection = mkSelection(schema, { textCutoff: 100_000 });
      const _ = (await postMessage(worker, {
        id: 'execAsObject',
        query: `SELECT ${selection.join()} FROM [${name}] ${assembleWhereClause(schema, modalId)} LIMIT 1`,
        bigInt: true
      }, { signal }));
      setActiveRow(_.objectValues[0])
    } else {
      setActiveRow(null)
    }
  }, [worker, schema, match?.modalId, match?.name])

  const closeModal = useCallback(() => {
    if (hState?.distance > 0 && hState.distance < MAX_HIST_DIST) navigate(-hState.distance);
    else navigate(`/${match.filename}/${type}/${name}`)
  }, [navigate, match?.filename, type, name, hState?.distance])

  const [activeFieldset, setActiveFieldset] = useState(null)

  const modal = useMemo(() => (
    <Modal
      isOpen={activeRow != null}
      onRequestClose={closeModal}
      className={modalStyles.content}
      style={{
        overlay: {
          backgroundColor: 'var(--overlay-background)',
          top: 'env(titlebar-area-height, 0px)',
          zIndex: 39,
        },
      }}
    >
      <ModalStyles hh={hh} style={{ height: '100%', display: 'flex', flexDirection: 'column' }} onClick={() => setActiveFieldset(null)}>
        <ModalHeader rows={rows} hState={hState} offset={offset} activeRow={activeRow} match={match} closeModal={closeModal} fixedRowIds={fixedRowIds} />
        <ModalBody worker={worker} name={name} filename={match?.filename} activeRow={activeRow} columns={columns} activeFieldset={activeFieldset} setActiveFieldset={setActiveFieldset} />
      </ModalStyles>
    </Modal>
  ), [activeFieldset, activeRow, closeModal, columns, fixedRowIds, hState, hh, match, name, offset, rows, worker])

  const hasFilter = globalFilter || filters?.length
  const hasSetting = hasFilter || sortBy?.length;

  const globalFilterRef = useRef(null);
  useHotkeys('ctrl+f, cmd+f', () => globalFilterRef.current?.focus(), {
    filter: (e) => { e.preventDefault(); return true },
  }, []);

  return <>
    <section {...getTableProps({ style: { height: '100%' } })} className="table explorer">
      <main {...getTableBodyProps({ style: { height: '100%', display: 'flex', flexDirection: 'column' } })}>
        {useMemo(() => <div style={{ height: hh, background: 'var(--thead-background)', borderBottom: '1px solid var(--lightgray)', display: 'flex', justifyContent: 'space-between' }}>
          <div className="ellipsis" style={{ display: 'flex', alignItems: 'center' }}>
            <Button className="ellipsis" onClick={resetFilters} disabled={!hasSetting} style={{ height: hh - 2 }}>Reset Filters</Button>
            <span className="ellipsis" style={{ margin: '0 5px' }}>Records: {count}{hasFilter ? '*' : null}</span>
          </div>
          <GlobalFilter
            ref={globalFilterRef}
            count={count}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>, [count, hasFilter, hasSetting, hh, resetFilters, setGlobalFilter, state.globalFilter])}
        <HeaderContext.Provider value={{ rows, offset, prepareRow, headerGroups, totalColumnsWidth, hhh, filters, sortBy, isResizingColumn, columnOrder, setColumnOrder }}>
          <EndlessTableX
            count={count}
            hh={hh}
            columns={columns}
            columnsById={columnsById}
            columnWidths={columnWidths}
            totalColumnsWidth={totalColumnsWidth}
            hhh={hhh}
            isResizingColumn={isResizingColumn}
            page={page}
            setPage={setPage}
            toggleFixed={toggleFixed}
            fixedIndices={fixedIndices}
            toggleFixedRows={toggleFixedRows}
            fixedRows={fixedRows}
            isApp={isApp}
          />
          {modal}
        </HeaderContext.Provider>
      </main>
    </section>
  </>
}

const RenderRow = ({ index, size, start, hhh, columnVirtualizer, isResizingColumn, fixedIndices, toggleFixedRows, fixed, lastFixed, fixedRowIndex }) => {
  const { rows, offset, prepareRow } = useContext(HeaderContext)

  const row = rows[index - offset];
  if (row) prepareRow(row);

  // DRY
  const [fixedCells, fixedIds] = useMemo(() => {
    const fixedCells = range(0, fixedIndices).map(i => row?.cells[i])
    const fixedIds = new Set(fixedCells.map(_ => _?.column.id))
    return [fixedCells, fixedIds]
  }, [row, fixedIndices])

  const style = useMemo(() => ({
    ...fixed ? {
      top: start + hhh,
      ...lastFixed ? { borderBottom: '1px solid var(--lightgray)' } : {},
    } : {
      transform: `translateY(${start + hhh}px)`,
    },
  }), [fixed, start, hhh, lastFixed])

  return !row ? null : (
    <div {...row?.getRowProps({ style })} className={cx('tr', { fixed, virtual: !fixed, odd: index % 2 === 1 })}>
      {fixedCells.map((_, colIndex) => (
        <RenderCell
          key={_.column.id}
          rowIndex={index}
          colIndex={colIndex}
          cell={_}
          width={_.column.totalWidth}
          height={size}
          start={_.column.totalLeft}
          isResizingColumn={isResizingColumn}
          toggleFixedRows={toggleFixedRows}
          fixed
          lastFixed={lastFixed}
          rowFixed={fixed}
          fixedRowIndex={fixedRowIndex}
        />
      ))}
      {columnVirtualizer.virtualItems.filter(_ => !fixedIds.has(_.key)).map(_ => (
        <RenderCell
          key={_.key}
          rowIndex={index}
          colIndex={_.index}
          cell={row.cells[_.index]}
          width={_.size}
          height={size}
          start={_.start}
          isResizingColumn={isResizingColumn}
        />
      ))}
    </div>
  )
}

function ModalHeader({ rows, hState, offset, activeRow, match, closeModal, fixedRowIds }) {
  const navigate = useNavigate();

  const prevIcon = vscode ? <i className="codicon codicon-arrow-up" /> : '▲'
  const nextIcon = vscode ? <i className="codicon codicon-arrow-down" /> : '▼'

  const { filename, type, name } = match ?? {}
  const { index: urlIndex, distance } = hState ?? {}

  const [prevId, nextId] = useMemo(() => {
    if (!urlIndex) return [];
    const i = urlIndex - offset - 1 + fixedRowIds.length;
    return [
      i - fixedRowIds.length > 0 ? rows?.[i - 1].id : null,
      i < rows?.length - 1 ? rows[i + 1].id : null,
    ].map(encodeId);
  }, [urlIndex, offset, fixedRowIds.length, rows])

  const [backTo, backToState, nextTo, nextToState] = useMemo(() => {
    const backTo = [filename, type, name, prevId, ''].join('/')
    const backToState = { index: urlIndex - 1, distance: distance + 1, backId: encodeId(activeRow?._rowid_) }
    const nextTo = [filename, type, name, nextId, ''].join('/')
    const nextToState = { index: urlIndex + 1, distance: distance + 1, backId: encodeId(activeRow?._rowid_) }
    return [backTo, backToState, nextTo, nextToState];
  }, [activeRow?._rowid_, distance, filename, name, nextId, prevId, type, urlIndex])

  useHotkeys('left', () => {
    return prevId === hState?.backId
      ? navigate(-1)
      : prevId != null
        ? navigate(backTo, { state: backToState })
        : null
  }, {}, [navigate, backTo, backToState])

  useHotkeys('right', () => {
    return nextId === hState?.backId
      ? navigate(-1)
      : nextId != null
        ? navigate(nextTo, { state: nextToState })
        : null
  }, {}, [navigate, nextTo, nextToState])

  return <>
    <div className="title-bar">
      {hState?.index != null
        ? <>
          {prevId === hState?.backId
            ? <Button title="Previous record" className="as-link" onClick={() => navigate(-1)}>{prevIcon}</Button>
            : prevId != null
              ? <LinkButton title="Previous record" to={backTo} state={backToState}>{prevIcon}</LinkButton>
              : <Button className="as-link" disabled>{prevIcon}</Button>}
          {nextId === hState?.backId
            ? <Button title="Next record" className="as-link" onClick={() => navigate(-1)}>{nextIcon}</Button>
            : nextId != null
              ? <LinkButton title="Next record" to={nextTo} state={nextToState}>{nextIcon}</LinkButton>
              : <Button className="as-link" disabled>{nextIcon}</Button>}
        </> : null}
      <Button className="as-link" onClick={closeModal} style={{ marginLeft: 8 }}>{vscode ? <i className="codicon codicon-discard" /> : '↙'}</Button>
      <code style={{ marginLeft: 8, marginRight: 8 }}>{urlIndex}</code>
    </div>
  </>;
}

function ModalBody({ worker, filename, name, activeRow, columns, activeFieldset, setActiveFieldset }) {
  const { columnOrder } = useContext(HeaderContext)

  const orderedColumns = useMemo(() => columnOrder.map(id => columns.find(_ => _.id === id)), [columnOrder, columns])

  return <div style={{ height: '100%', overflowY: 'auto', overscrollBehavior: 'none', WebkitOverflowScrolling: 'touch' }}><div className="fluid-grid" style={{ padding: 20 }}>
    {activeRow != null && orderedColumns.slice(1, orderedColumns.length - 1)
      .map(({ id, schemaInfo: { pk, maxPk, fk, fkTable, fkName, type: schemaType, typeAffinity } }) => {
        const value = activeRow[id];
        const sub = schemaType?.match(/\(([\d,]+)\)/)
        const legend = <>
          <strong>{id}</strong>
          {' '}
          <KeySymbols pk={pk} maxPk={maxPk} fk={fk} fkTable={fkTable} fkName={fkName} />
        </>
        return <Fieldset key={id}
          legend={legend}
          className={activeFieldset === id ? 'active' : ''}
          onClick={e => { e.stopPropagation(); setActiveFieldset(id) }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
            {schemaType === 'BLOB' || value instanceof Uint8Array
              ? <>
                <DownloadButton text={<FormatBytes value={value?.byteLength ?? value ?? -1} />} worker={worker} filename={filename} name={name} columnId={id} rowId={activeRow._rowid_} />
              </>
              : schemaType === 'TEXT' || schemaType === 'LONGVARCHAR'
                ? <TextArea onFocus={_ => _.target.select()} readOnly value={value?.toString() ?? ''} placeholder="NULL" rows={3} />
                : <Input onFocus={_ => _.target.select()} readOnly value={value?.toString() ?? ''} placeholder="NULL" maxLength={sub?.[1]} />}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
              <small style={{ color: 'var(--null)', textTransform: 'uppercase' }}>{schemaType !== 'BLOB' || value instanceof Uint8Array ? 'readonly' : 'download'}</small>
              <div style={{ color: 'var(--muted)' }}>
                <small>{schemaType}</small>
                {' '}
                <span title={schemaType || 'Unknown'} style={{ userSelect: 'none' }}>
                  {affinityEmoji.get(typeAffinity)}
                </span>
              </div>
            </div>
          </div>
        </Fieldset>
      })}
  </div></div>
}

function EndlessTableX({
  count,
  hh,
  columns,
  columnsById,
  columnWidths,
  totalColumnsWidth,
  hhh,
  isResizingColumn,
  setPage,
  page,
  toggleFixed,
  fixedIndices,
  toggleFixedRows,
  fixedRows,
  isApp,
}) {
  const { rows, offset, columnOrder } = useContext(HeaderContext)
  const parentRef = useRef();

  const overscan = 1;
  const rowVirtualizer = useVirtual({
    size: count,
    parentRef,
    overscan,
    useObserver: useContentRect,
    estimateSize: useCallback(() => hh, [hh]),
    keyExtractor: useCallback(index => rows[index - offset]?.id ?? `__temp-index-${index}`, [rows, offset])
  });

  const columnVirtualizer = useVirtual({
    horizontal: true,
    size: columns.length,
    parentRef,
    overscan: 1,
    useObserver: useContentRect,
    estimateSize: useCallback(i => {
      return columnWidths[columnOrder[i]] ?? columnsById[columnOrder[i]]?.width ?? defaultColumn.width
    }, [columnWidths, columnOrder, columnsById]),
    keyExtractor: useCallback(i => {
      return columnsById[columnOrder[i]]?.id ?? `__temp-col-index-${i}`
    }, [columnOrder, columnsById]),
  });

  const onClick = useCallback(_ => {
    rowVirtualizer.scrollToIndex((_.target.value - 1) * PER_PAGE - 1, { align: 'start' })
  }, [rowVirtualizer]);

  const goTo = useCallback((page) => {
    return () => rowVirtualizer.scrollToIndex(((page - 1) * PER_PAGE - 1), { align: 'start' })
  }, [rowVirtualizer]);

  const goToRef = useRef(null);
  useEffect(() => {
    if (goToRef.current) goToRef.current.value = page
  }, [page])

  useEffect(() => {
    const visibleStartIndex = at(rowVirtualizer.virtualItems, overscan)?.index;
    const visibleEndIndex = at(rowVirtualizer.virtualItems, -overscan - 1)?.index;
    if (visibleStartIndex != null && visibleEndIndex != null) {
      setPage(1 + Math.floor(visibleEndIndex / PER_PAGE));
      // setPage(1 + Math.floor((visibleEndIndex + visibleStartIndex) / 2 / PER_PAGE));
    }
  }, [rowVirtualizer.virtualItems[0]?.index, setPage]) // eslint-disable-line react-hooks/exhaustive-deps

  const [isDragging, setIsDragging] = useState(false)

  return <>
    <div ref={parentRef}
      style={useMemo(() => ({
        flex: 1,
        overflowX: 'auto',
        overflowY: isDragging ? 'hidden' : 'auto',
        pointerEvents: isResizingColumn ? 'none' : '',
        overscrollBehavior: 'none',
      }), [isDragging, isResizingColumn])}
    >
      <div
        style={useMemo(() => ({
          position: "relative",
          height: rowVirtualizer.totalSize + hhh,
          width: totalColumnsWidth,
          // pointerEvents: isScrolling ? 'none' : '',
        }), [hhh, rowVirtualizer.totalSize, totalColumnsWidth])}
      >
        <TableHeaderRow
          setIsDragging={setIsDragging}
          fixedIndices={fixedIndices}
          toggleFixed={toggleFixed}
        />
        {fixedRows.map((_, fixedRowIndex) => (
          <RenderRow
            key={_.id}
            index={_.index + offset}
            columnVirtualizer={columnVirtualizer}
            isResizingColumn={isResizingColumn}
            size={hh}
            start={_.index * hh}
            hhh={hhh}
            fixedIndices={fixedIndices}
            toggleFixedRows={toggleFixedRows}
            lastFixed={_.index === fixedRows.length - 1}
            fixedRowIndex={fixedRowIndex}
            fixed
          />
        ))}
        {rowVirtualizer.virtualItems.map(_ => (
          <RenderRow
            key={_.key}
            index={_.index}
            columnVirtualizer={columnVirtualizer}
            isResizingColumn={isResizingColumn}
            size={_.size}
            start={_.start}
            hhh={hhh}
            fixedIndices={fixedIndices}
            toggleFixedRows={toggleFixedRows}
          />
        ))}
      </div>
    </div>
    <Pagination count={count} page={page} goTo={goTo} onClick={onClick} hh={hh} isApp={isApp} />
  </>
}

// #region Graveyard
// +===============+
// {/* <VariableSizeGrid
//   ref={gridRef}
//   width={width}
//   height={height - hh}
//   rowCount={rows.length}
//   rowHeight={() => hh}
//   columnCount={columns.length}
//   columnWidth={i => {
//     return Math.max(
//       state.columnResizing.columnWidths[columns[i].accessor] ?? columns[i].width ?? 150,
//       12,
//     )
//   }}
//   onScroll={_ => { requestAnimationFrame(() => { headerRef.current.scrollLeft = _.scrollLeft }) }} // HACK
// >
//   {RenderCell}
// </VariableSizeGrid> */}

// const OuterElement = useMemo(() => (console.log('history changed??'), forwardRef(({ children, onScroll, ...rest }, ref) => {
//   // const { headerGroups, totalColumnsWidth, hhh } = useContext(HeaderContext)
//   // const myOnScroll = useDebouncedCallback((_) => {
//   //   console.log(_, ref.current)
//   // }, 500)
//   const debounced = useDebouncedCallback(_ => {
//     updateHistoryState(history, { scrollLeft: _.target.scrollLeft });
//   }, 1000)
//   const myOnScroll = useCallback(_ => {
//     debounced(_);
//     onScroll(_);
//   }, [onScroll])
//   return <div ref={ref} onScroll={myOnScroll} {...rest} >{children}</div>
// })), [history])

// const hHeight = height - hh;
// const tp = Math.ceil((innerRef.current?.scrollHeight - (height - hh) / 2) / (PER_PAGE * hh)) || 0
// const totalPages = Math.max(1, tp)

// const headerRef = useRef(null);
// const currentColOrder = useRef();
// useEffect(() => {
//   // console.log(state.columnResizing.columnWidths, gridRef.current)
//   gridRef.current?.resetAfterColumnIndex(0);
// }, [state.columnResizing.columnWidths])

// console.log(state)

// const onScroll = useDebouncedCallback(({ scrollOffset, scrollUpdateWasRequested }) => {
//   if (!scrollUpdateWasRequested) {
//     console.log('backup scrollpos', scrollOffset)
//     updateHistoryState(history, { scrollTop: scrollOffset })
//     // history.replace(pathname, { sortBy, filters, columnWidths, scrollOffset })
//   }
// }, 500)

// useEffect(() => {
//   if (_sameUrl && !isResizingColumn) {
//     // console.log('backup state')
//     // updateHistoryState(history, { sortBy, filters, globalFilter, columnWidths })
//   }
// }, [_sameUrl, history, sortBy, filters, globalFilter, columnWidths, isResizingColumn, pathname])

// useEffect(() => {
//   if (!_sameUrl) {
//     console.log({ 
//       top: hState?.scrollTop ?? 0,
//       left: hState?.scrollLeft ?? 0,
//      })
//     outerRef.current?.scrollTo({
//       top: hState?.scrollTop ?? 0,
//       left: hState?.scrollLeft ?? 0,
//       // behavior: 'smooth'
//     });
//   }
// }, [_sameUrl, hState?.scrollLeft, hState?.scrollTop])

// const [isScrolling, setIsScrolling] = useState(false)
// const timerId = useRef(null)
// const handleScroll = useCallback(() => {
//   clearTimeout(timerId.current);
//   timerId.current = setTimeout(() => { setIsScrolling(false) }, 120)
//   setIsScrolling(true);
// }, []);

// function updateHistoryState(history, patch) {
//   const { location: { state, pathname, search } } = history;
//   if (!isEqual(state, { ...state, ...patch })) {
//     history.replace(pathname + search, { ...state, ...patch })
//   }
// }

// #endregion

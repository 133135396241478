export type Resolver<T> = (value: T | PromiseLike<T>) => void;
export type Rejecter = (reason?: any) => void;
export type ResolvablePromise<T> = Promise<T> & { resolve: Resolver<T>, reject: Rejecter }

export function resolvablePromise<T>(): ResolvablePromise<T> {
  let resolve!: Resolver<T>;
  let reject!: Rejecter;
  const p = new Promise<T>((r, j) => { resolve = r; reject = j });
  return Object.assign(p, { resolve, reject })
}

export function withSettled<P extends Promise<T>, T>(p: P): P & { settled: boolean } {
  const sp = p as P & { settled: boolean }
  sp.settled = false;
  p.finally(() => { sp.settled = true })
  return sp;
}

const queueMicrotask = typeof globalThis.queueMicrotask === "function"
  ? globalThis.queueMicrotask
  : (callback: VoidFunction) => Promise.resolve().then(callback).catch(e => setTimeout(() => { throw e }));

export class ExtendablePromise<T> implements Promise<T[]> {
  #promise: ResolvablePromise<T[]>;
  #numAdded = 0
  #numSettled = 0
  #values: T[] = []
  #settled = false

  constructor(...args: (T | PromiseLike<T>)[]) {
    this.#promise = resolvablePromise()
    this.waitUntil(...args);
    queueMicrotask(() => {
      if (this.#numAdded === 0) {
        this.#promise.resolve([]);
      }
    });
  }

  #fulfill = (i: number, value: T) => {
    this.#values[i] = value;
    if (++this.#numSettled === this.#numAdded) {
      this.#settled = true;
      this.#promise.resolve(this.#values);
    }
  };

  waitUntil(...args: (T | PromiseLike<T>)[]) {
    if (this.#settled) {
      // throw new Error("Can't add promises to settled promise");
      console.warn("Can't add promises to settled promise");
    }
    for (let j = 0; j < args.length; ++j) {
      let i = this.#numAdded + j;
      Promise.resolve(args[j])
        .then(_ => this.#fulfill(i, _), _ => this.#promise.reject(_));
    }
    this.#numAdded += args.length;
  };

  get settled() { return this.#settled }

  then<TResult1 = T[], TResult2 = never>(onfulfilled?: ((value: T[]) => TResult1 | PromiseLike<TResult1>) | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null): Promise<TResult1 | TResult2> {
    return this.#promise.then(onfulfilled, onrejected);
  }
  catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | null): Promise<T[] | TResult> {
    return this.#promise.catch(onrejected);
  }
  finally(onfinally?: (() => void) | null): Promise<T[]> {
    return this.#promise.finally(onfinally);
  }
  get [Symbol.toStringTag]() { return 'ExtendablePromise' }
}

const NEVER = new Promise<undefined>(() => { });
export async function raceTruthy<T>(iterable: Iterable<Promise<T | undefined | null>>) {
  const ps = [...iterable].map(_ => Promise.resolve(_));
  let { length } = ps;
  const continueIfNullish = (value: T | undefined | null) => value != null
    ? value
    : --length > 0
      ? NEVER
      : undefined;
  const candidates = ps.map(p => p.then(_ => continueIfNullish(_)))
  return Promise.race(candidates);
}

/* eslint-disable no-unused-vars */
/* eslint-disable import/no-webpack-loader-syntax */
/* global vscode, plausible, implausible */

import React, { useState, useEffect, useMemo, useRef, useCallback, forwardRef, memo } from "react";

import { useTable, useExpanded, useFilters, useFlexLayout, useAbsoluteLayout } from 'react-table'
import styled from 'styled-components'
import { NavLink, Link, Routes, Route, useLocation, useParams, useNavigate, useMatch } from "react-router-dom";
import { default as Allotment } from '@qwtel/allotment/src/allotment'
import { fileOpen, directoryOpen, fileSave, supported } from 'browser-fs-access';
import { StorageArea } from 'kv-storage-polyfill/src/index';
import { default as RelativeTime } from '@yaireo/relative-time';
import { useHotkeys } from 'react-hotkeys-hook'
import { useMedia } from 'react-use';
import { default as cx } from "classnames";
import { useDrag, useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'

import { scrollbarWidth } from './scroll-bar-width'
import { postMessage } from "./post-message-x";
import { ExploreView, KeySymbols, KEY_SEP, affinityEmoji, getFromHandleStorage, SampleHandle, formatBytes } from './Explorer';

import { Button, Input } from './uilib';
import { useAsyncEffect, useLaunchQueueFiles, useStorageArea } from "./hooks";

import favicon from './favicon.png';
import faviconP from './favicon.webp';

import styles from './style.module.css';

// const isVSC = process.env.SQLITE_VIEWER_ENV === 'vscode';

const pathToWorker = document.getElementById('worker').href
const pathToSQLWasm = document.getElementById('sql-wasm').href

const handleStorage = new StorageArea('handles');
const fileCacheStorage = new StorageArea('files');

const MB = 2 ** 20;
const GB = 2 ** 30;
const MAX_FILE_SIZE = 1 * GB

const openHandles = new Set();
const docTitle = document.title;

const rtf1 = new RelativeTime({ locale: 'en' })

const launchSearchParams = new URLSearchParams(window.location.search);
const launchedAsApp = launchSearchParams.get('source') === 'app';

async function slurp(ait) {
  const res = [];
  for await (const _ of ait) res.push(_)
  return res;
}

function useRouteMatch(pattern) {
  return useMatch({
    path: pattern,
    end: false,
  })
}

const SidebarStyles = styled.div`
  .table {
    border-spacing: 0;
    min-width: 100%;

    .th, .td {
      margin: 0;
      line-height: 26px;
      // border-bottom: 1px solid var(--lightgray);
      // border-right: 1px solid var(--lightgray);
      height: ${props => props.hh}px;
      padding: 0px 3px;
      // overflow: hidden;
      white-space: nowrap;
      // text-overflow: ellipsis;
      i { color: var(--vscode-list-deemphasizedForeground) }
    }
    .tr > .td:first-child {
      i { line-height: ${props => props.hh - 1}px }
    }
  }
  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .file-menu {
    display: flex; 
    align-items: center; 
    /* border-top: 1px solid var(--lightgray); */
    border-bottom: 1px solid var(--lightgray); 
    background: var(--gray6);
    padding-left: env(titlebar-area-x, 0);
    -webkit-app-region: drag;
    app-region: drag;
  }
  .no-drag {
    -webkit-app-region: no-drag;
    app-region: no-drag;
  }
`

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, setFilter },
  rows,
}) {
  return (
    <>
      <Input
        value={filterValue || ''}
        onChange={e => setFilter(e.target.value || undefined)}
        placeholder={`Search tables...`}
        style={{ width: '150px', flex: '1 1 150px' }}
        disabled={rows.length === 0}
      />
    </>
  )
}

async function checkPermission(fileHandle, mode = 'read') {
  const options = {};
  if (mode) {
    options.mode = mode;
  }
  if ((await fileHandle.queryPermission(options)) === 'granted') {
    return true;
  }
  return false;
}

// If the declared type contains the string "INT" then it is assigned INTEGER affinity.
// If the declared type of the column contains any of the strings "CHAR", "CLOB", or "TEXT" then that column has TEXT affinity. Notice that the type VARCHAR contains the string "CHAR" and is thus assigned TEXT affinity.
// If the declared type for a column contains the string "BLOB" or if no type is specified then the column has affinity BLOB.
// If the declared type for a column contains any of the strings "REAL", "FLOA", or "DOUB" then the column has REAL affinity.
// Otherwise, the affinity is NUMERIC.
function getAffinity(type) {
  if (/DATE/i.test(type)) return 'DATE';
  if (/BOOLEAN/i.test(type)) return 'BOOLEAN'
  if (/INT/i.test(type)) return 'INTEGER'
  if (/(CHAR|CLOB|TEXT)/i.test(type)) return 'TEXT'
  if (/BLOB/i.test(type)) return 'BLOB'
  if (/(REAL|FLOA|DOUB)/i.test(type)) return 'REAL'
  if (/(NUMERIC|DECIMAL)/i.test(type)) return 'NUMERIC'
  return '?'
}

function Picture({ src, webp, ...props }) {
  return <picture>
    <source srcSet={webp} type="image/webp" />
    <img src={src} alt="Logo" {...props} />
  </picture>
}

function FileMenu({ filename, cachedFile, refreshing, refreshFileCallback, openFile, hh }) {
  useHotkeys('ctrl+o, cmd+o', () => openFile(), {
    filter: (e) => { e.preventDefault(); return true },
  }, [openFile]);

  return (
    <div className="file-menu" style={{ height: `calc(env(titlebar-area-height, ${hh}px) + 1px)` }}>
      <span className="ellipsis no-drag" style={{ margin: '0 10px 0 5px' }}>
        {cachedFile
          ? <Link to="" title={`You are viewing a cached file that was last uploaded ${rtf1.from(cachedFile.lastUploaded)}.\nRe-open the file to view the latest contents.`} style={{ cursor: 'help' }}><span style={{ padding: 5 }}>{'⚠️'}</span></Link>
          : <Link to="">
            <Picture src={favicon} webp={faviconP} alt="Logo" style={{ width: hh, height: hh, padding: 5, margin: 0, float: 'left' }} />
          </Link>}
        {/* <span style={{ lineHeight: `${hh}px` }}>{filename ?? ''}</span> */}
      </span>
      <span className="no-drag" style={{ display: 'flex', overflow: 'hidden', alignItems: 'center', height: '100%' }}>
        <Button className={cx('ellipsis')} onClick={openFile} title="Open File" style={{ height: hh - 2, padding: '0 8px' }}>{'📁'} Open File</Button>
      </span>
    </div>
  )
}

function FSAccessRefresh({ filename, refreshFileCallback, refreshing, hh }) {
  return <>
    {supported && filename
      ? <Button type="button" title="Refresh" onClick={refreshFileCallback} style={{ height: hh - 2, padding: '0 8px' }} disabled={refreshing}>
        {refreshing ? '…' : '🔄'}
      </Button>
      : null}
  </>
}

function VSCodeRefresh({ refreshing, setRefreshing, hh }) {
  const [active, setActive] = useState();
  useHotkeys('ctrl+r, cmd+r', () => vscode && setActive(true), { keydown: true });
  useHotkeys('ctrl+r, cmd+r', () => vscode && setActive(false), { keyup: true });

  const refresh = useCallback(() => {
    setRefreshing(true);
    vscode.postMessage({ type: 'refresh' })
  }, [setRefreshing])

  return (
    <Button type="button" className={cx({ active })} title="Refresh" onClick={refresh} style={{ width: hh, marginLeft: -3 }} disabled={refreshing}>
      {refreshing ? '…' : <i className="codicon codicon-refresh" />}
    </Button>
  )
}

const worker = (async () => {
  let worker;
  if (vscode) {
    const blob = await fetch(pathToWorker).then(_ => _.blob());
    worker = new Worker(URL.createObjectURL(blob));
  } else {
    worker = new Worker(new URL('./worker.js', import.meta.url));
  }
  await postMessage(worker, { id: 'setup', ...vscode ? { pathToSQLWasm } : {} });
  return worker;
})();

async function askPermission(fileHandle, mode = 'read') {
  const options = {};
  if (mode) {
    options.mode = mode;
  }
  // Check if permission was already granted. If so, return true.
  if ((await fileHandle.queryPermission(options)) === 'granted') {
    return true;
  }
  // Request permission. If the user grants permission, return true.
  if ((await fileHandle.requestPermission(options)) === 'granted') {
    return true;
  }
  // The user didn't grant permission, so return false.
  return false;
}

function App() {
  const [tableGroups, setTableGroups] = useState([]);
  const [allTableGroups, setAllTableGroups] = useState([]);
  const [search, setSearch] = useState('');
  const [error, _setError] = useState(null);

  const setError = useCallback((_) => {
    if (_ instanceof DOMException && _.message === 'AbortError') { }
    else _setError(_);
  }, [])

  const navigate = useNavigate();

  const isApp = useMedia('(display-mode:standalone)') || launchedAsApp;
  const [isFs] = useStorageArea('fs', isApp)
  useEffect(() => {
    if (!vscode) document.body.classList.toggle('fs', isApp || !!isFs);
  }, [isFs, isApp])

  const [workerId, setWorkerId] = useState(0);

  const hh = 28

  const [handle, setHandle] = useState(null);
  const [cachedFile, setCachedFile] = useState(null);

  const filename = useRouteMatch('/:filename/')?.params?.filename ?? ''
  const { type = '', name = '' } = useRouteMatch('/:filename/:type/:name')?.params ?? {}
  const { modalId } = useRouteMatch('/:filename/:type/:name/:modalId/')?.params ?? {}

  const mkSubRows = useMemo(() => async ({ type, ...table }, { signal } = {}) => {
    const tbln = table.tbl_name;
    // let q;
    const tableInfo = await postMessage(worker, {
      id: 'execAsObject',
      query: `
        SELECT ti.*,
          Group_Concat(fkl.[table], "${KEY_SEP}") AS [table],
          Group_Concat(fkl.[from], "${KEY_SEP}") AS [from],
          CASE WHEN fkl.[to] IS NOT NULL THEN 1 ELSE 0 END AS fk 
        FROM pragma_table_info('${tbln}') AS ti 
        LEFT JOIN (SELECT * FROM pragma_foreign_key_list('${tbln}')) AS fkl 
        ON fkl.[to]=ti.name
        GROUP BY ti.name
        ORDER BY ti.cid
      `,
    }, { signal });
    // console.log(q)

    const maxPk = Math.max(...tableInfo.objectValues.map(({ pk }) => pk).filter(_ => _))

    return {
      ...table,
      sub: type,
      subRows: tableInfo.objectValues.map(({ cid, name, type, notnull, dflt_value, pk, fk, table, from }) => ({
        name,
        type,
        typeAffinity: getAffinity(type),
        pk,
        maxPk,
        fk,
        fkTable: table,
        fkName: from,
      }))
    }
  }, [])

  useAsyncEffect(async ({ signal }) => {
    try {
      const tableData = await postMessage(worker, {
        id: 'execAsObject',
        query: `SELECT * FROM sqlite_master`,
      }, { signal });

      const tables = tableData.objectValues.filter(_ => _.type === 'table');
      // const indices = tableData.objectValues.filter(_ => _.type === 'index');
      // const views = tableData.objectValues.filter(_ => _.type === 'view');
      // const triggers = tableData.objectValues.filter(_ => _.type === 'trigger');

      const tableValues = [
        { type: 'table', subRows: await Promise.all(tables.map(_ => mkSubRows(_, { signal }))) },
        // { type: 'index', subRows: await Promise.all(indices.map(_ => mkSubRows(_, { signal }))) },
        // { type: 'view', subRows: views }, // TODO
        // { type: 'trigger', subRows: triggers }, // TODO
      ];

      setAllTableGroups(tableValues)
    } catch (_) {
      // Swallow error (caught in similar query below)
      // TODO: avoid duplicate query?
      // setError(_)
    }
  }, [workerId]);

  useEffect(() => {
    if (filename && !name) {
      const f = allTableGroups?.[0]?.subRows[0];
      if (f?.name) navigate(`${filename}/table/${f.name}/`, { replace: true })
    }
  }, [allTableGroups]) // eslint-disable-line react-hooks/exhaustive-deps

  useAsyncEffect(async ({ signal }) => {
    try {
      const tableData = await postMessage(worker, {
        id: 'execAsObject',
        query: `SELECT * FROM sqlite_master WHERE name LIKE '%${search}%'`,
      }, { signal });

      const tables = tableData.objectValues.filter(_ => _.type === 'table');
      // const indices = tableData.objectValues.filter(_ => _.type === 'index');
      // const views = tableData.objectValues.filter(_ => _.type === 'view');
      // const triggers = tableData.objectValues.filter(_ => _.type === 'trigger');

      const tableValues = [
        {
          name: <>
            {/* <span style={{ float: 'right', marginRight: 3 }}>{vscode ? <i className="codicon codicon-window"/> : '🗓️'}</span> */}
            Tables ({tables.length})
          </>, subRows: await Promise.all(tables.map(_ => mkSubRows(_, { signal })))
        },
        // { name: `🏷️ Indices (${indices.length})`, subRows: await Promise.all(indices.map(_ => mkSubRows(_, { signal }))) },
        // { name: `🏞 Views (${views.length})`, subRows: views }, // TODO
        // { name: `📜 Triggers (${triggers.length})`, subRows: triggers }, // TODO
      ];

      setTableGroups(tableValues)
    } catch (_) {
      setError(_)
    }
  }, [workerId, search]);

  const [refreshing, setRefreshing] = useState(false)

  const _reg = useRef(false)
  useAsyncEffect(async ({ signal }) => {
    if (!vscode) return;
    if (_reg.current) return; _reg.current = true;

    window.addEventListener('message', async e => {
      const { type, body, requestId } = e.data;
      const tooLargeErrorMsg = 'File too large. You can change this limit in the settings, but be aware that large files cause performance issues and can even crash vscode'
      switch (type) {
        case 'init': {
          // editor.setEditable(body.editable);
          if (body.untitled) {
            // await editor.resetUntitled();
            return;
          } else {
            const { buffer, byteOffset, byteLength } = body.value
            if (!buffer && !byteOffset && !byteLength) {
              setError(new Error(tooLargeErrorMsg))
              return;
            }
            const data = new Uint8Array(buffer, byteOffset, byteLength);
            try {
              setWorkerId(await postMessage(worker, { id: 'data', data }, [data.buffer]));
              navigate(`/${body.filename}/`, { replace: true });
            } catch (_) {
              setError(_)
            }
            return;
          }
        }
        case 'update': {
          const { buffer, byteOffset, byteLength } = body.value
            if (!buffer && !byteOffset && !byteLength) {
              setError(new Error(tooLargeErrorMsg))
              return;
            }
          const data = new Uint8Array(buffer, byteOffset, byteLength);
          try {
            setWorkerId(await postMessage(worker, { id: 'data', data }, [data.buffer]));
            setRefreshing(false)
          } catch (_) {
            setError(_)
          }
          return;
        }
        case 'getFileData': {
          // vscode.postMessage({ type: 'response', requestId, body: Array.from(db.export()) });
          return;
        }
        default: break;
      }
    }, { signal });
  }, []);

  // const schema = useMemo(() =>
  //   tableGroups[['table', 'index', 'view', 'trigger'].indexOf(type)]?.subRows.find(_ => _.name === name),
  //   [type, name, tableGroups]
  // )
  // const schemaData = useMemo(() => schema?.subRows ?? [], [schema])

  const refreshFileCallback = useCallback(async () => {
    try {
      const handle = await getFromHandleStorage(filename);
      openHandles.add(handle)
      if (handle) {
        if (await checkPermission(handle)) {
          setHandle(null)
          setRefreshing(true);
          const file = await handle.getFile()
          const data = await file.arrayBuffer()
          setError(null)
          setWorkerId(await postMessage(worker, { id: 'data', data }, [data]));
          setRefreshing(false);
        } else {
          setHandle(handle)
        }
      } else {
        setAllTableGroups([]);
        setTableGroups([])
        setHandle(null)
      }
    } catch (_) {
      setError(_)
    }
  }, [filename, setError])

  const askPermissionCallback = useCallback(async () => {
    try {
      const handle = await getFromHandleStorage(filename);
      if (handle) {
        await askPermission(handle)
        setHandle(null)

        const file = await handle.getFile()
        const data = await file.arrayBuffer()
        setError(null)
        setWorkerId(await postMessage(worker, { id: 'data', data }, [data]));
      }
    } catch (_) {
      setError(_)
    }
  }, [filename, setError]);

  const columns = React.useMemo(
    () => [
      {
        id: 'expander',
        minWidth: 40,
        width: 40,
        maxWidth: 40,
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, refreshing, setRefreshing }) => {
          return vscode
            ? <VSCodeRefresh refreshing={refreshing} setRefreshing={setRefreshing} hh={hh} />
            : <FSAccessRefresh refreshing={refreshing} refreshFileCallback={refreshFileCallback} filename={filename} hh={hh} />
          // <span {...getToggleAllRowsExpandedProps()}>
          //   {isAllRowsExpanded ? '👇' : '👉'}
          // </span>
        },
        disableFilters: true,
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 1}rem`,
                  color: 'var(--icon-color)',
                },
              })}
            >
              {/* {row.isExpanded ? '👇' : '👉'} */}
              {vscode
                ? row.isExpanded ? <i className="codicon codicon-chevron-down" /> : <i className="codicon codicon-chevron-right" />
                : row.isExpanded ? '▼' : '▶'}
            </span>
          ) : null,
      },
      {
        id: 'name',
        Header: '',
        accessor: _ => _['name'],
        Cell: ({ row, ...args }) => {
          return row.depth === 1 && ['table', 'view'].includes(row.original.sub)
            ? (
              <NavLink
                tabIndex={0}
                to={`${filename}/${row.original.sub}/${row.original.name}/`}
                className={({ isActive }) => isActive ? 'active' : ''}
              >{row.values.name}</NavLink>
            ) : row.depth === 2
              ? (() => {
                const { name: colName, pk, maxPk, fk, fkTable, fkName, type: schemaType, typeAffinity } = row.original;
                return <span>
                  {row.values.name}
                  {' '}
                  <KeySymbols pk={pk} maxPk={maxPk} fk={fk} fkTable={fkTable} fkName={fkName} />
                  {' '}
                  <span title={schemaType || 'Unknown'} style={{ float: 'right', marginRight: 5 }}>
                    {affinityEmoji.get(typeAffinity)}
                  </span>
                </span>
              })() : (
                <span>{row.values.name}</span>
              )
        }
      },
      // {
      //   id: 'type',
      //   Header: 'Type',
      //   accessor: _ => _['type'],
      //   disableFilters: true,
      //   Cell: ({ value }) => <code>{value}</code>
      // },
      // {
      //   id: 'sql',
      //   Header: 'SQL',
      //   Cell: ({ row }) => <code>{row.values.sql}</code>,
      //   accessor: _ => _['sql'],
      // },
    ],
    [filename, refreshFileCallback]
  );

  // console.log(tableGroups[0])
  // const target = React.useRef(null)
  // const size = useSize(target)

  const [freshFile, setFreshFile] = useState(null);
  const [recentlyOpened, setRecentlyOpened] = useState([])

  useAsyncEffect(async () => {
    if (vscode) return;
    if (supported) {
      const handles = await slurp(handleStorage.keys())
      setRecentlyOpened(handles);
    } else {
      const files = await slurp(fileCacheStorage.values())
      const fileNames = files.sort((a, b) => b.lastUploaded - a.lastUploaded).map(_ => _.file.name)
      setRecentlyOpened(fileNames);
    }
  }, [])

  useAsyncEffect(async ({ signal }) => {
    if (vscode) return;
    setAllTableGroups([]);
    setTableGroups([])

    if (freshFile?.name !== filename) {
      setError(null)
      setFreshFile(null)

      if (supported) {
        refreshFileCallback();
      } else {
        try {
          const { file, lastUploaded } = await fileCacheStorage.get(filename) ?? {}
          if (file) {
            const data = await file.arrayBuffer();
            file.lastUploaded = lastUploaded;
            setCachedFile(file)
            setWorkerId(await postMessage(worker, { id: 'data', data }, { transfer: [data], signal }));
          } else {
            setCachedFile(null)
          }
        } catch (_) {
          setError(_);
        }
      }
    }
  }, [filename])

  const processFile = useCallback(async (file, { sample = false } = {}) => {
    try {
      if (file.size < MAX_FILE_SIZE) {
        if (supported && file.handle) {
          openHandles.add(file.handle)
          await handleStorage.set(file.name, { handle: file.handle })
        } else {
          const estimate = await navigator.storage?.estimate?.() ?? { usage: 0, quota: Number.POSITIVE_INFINITY }
          if (estimate.usage + file.size <= estimate.quota) {
            await fileCacheStorage.set(file.name, { file, lastUploaded: new Date() })
          } else {
            setError(new Error('Exceeding storage quota.'))
          }
        }

        setHandle(null)
        setFreshFile(file)
        if (filename !== file.name) navigate(`/${file.name}/`);

        const data = await file.arrayBuffer();
        setError(null)
        setWorkerId(await postMessage(worker, { id: 'data', data }, [data]));
        setCachedFile(null)
        // if (!vscode) window.plausible?.('open', { props: { sample } })
      } else {
        setError(new Error(`File too large. SQLite Viewer Web can only handle files up to ${formatBytes(MAX_FILE_SIZE)}.`))
      }
    } catch (_) {
      if (_ instanceof DOMException && _.message === 'AbortError') { }
      setError(_)
    }
  }, [navigate, filename, setError]);

  const openFile = useCallback(async () => {
    const file = await fileOpen({
      mimeTypes: ['application/vnd.sqlite3', 'application/x-sqlite3'],
      extensions: ['.sqlite', '.db', '.sqlite3', '.db3'], // TODO:...
    });
    processFile(file);
  }, [processFile])

  const launchFiles = useLaunchQueueFiles();
  useAsyncEffect(async () => {
    const handle = launchFiles?.[0];
    const file = await handle?.getFile();
    if (file) {
      file.handle = handle;
      processFile(file)
    }
  }, [launchFiles])

  const loadSample = useCallback(async (uint8Array) => {
    const file = new File([uint8Array], 'Chinook_Sqlite.sqlite')
    file.handle = new SampleHandle(file)
    processFile(file, { sample: true })
  }, [processFile])

  // console.log(type, name)
  useEffect(() => {
    const tit = [modalId, name, { table: 'Table', view: 'View', index: 'Index', trigger: 'Trigger' }[type], filename].filter(_ => _).join(' | ')
    document.title = tit || docTitle
  }, [modalId, name, type, filename])

  useHotkeys('ctrl+r, cmd+r', e => {
    e.preventDefault();
    if (supported && isApp) {
      refreshFileCallback()
    } else if (vscode && !refreshing) {
      setRefreshing(true)
      vscode.postMessage({ type: 'refresh' })
    }
  }, {
    filter: () => (supported && isApp) || vscode,
    filterPreventDefault: false,
  }, [refreshFileCallback, setRefreshing]);

  const handleTransferItem = useCallback(async (item) => {
    if (item.kind === 'file') {
      if ('getAsFileSystemHandle' in item) {
        const handle = await item.getAsFileSystemHandle();
        if (handle.kind !== 'directory') { // TODO: necessary?
          const file = await handle.getFile();
          file.handle = handle;
          if (file) processFile(file);
        }
      } else {
        const file = await item.getAsFile()
        if (file) processFile(file);
      }
    }
  }, [processFile])

  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop({ files, items, dataTransfer }) {
      const item = [...dataTransfer.items][0];
      if (item) handleTransferItem(item)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }), [])

  return (
    <div className={cx({ [styles.drop]: isOver })} ref={!vscode ? dropRef : null} style={{ width: '100%', height: '100%' }}>
      <Allotment sizes={[1, 5]}>
        <SidebarStyles hh={24} style={{ display: 'flex', flexDirection: 'column', height: '100%', background: 'var(--sidebar-background)' }}>
          {vscode
            ? null
            : <FileMenu
              filename={filename}
              cachedFile={cachedFile}
              refreshing={refreshing}
              refreshFileCallback={refreshFileCallback}
              openFile={openFile}
              hh={hh}
            />}
          <Table
            columns={columns}
            data={tableGroups}
            setSearch={setSearch}
            hh={hh}
            refreshing={refreshing}
            setRefreshing={setRefreshing}
          />
        </SidebarStyles>
        <ExploreView
          hh={hh}
          worker={worker}
          workerId={workerId}
          tableGroups={allTableGroups}
          handle={handle}
          error={error}
          filename={filename}
          type={type}
          name={name}
          modalId={modalId}
          openFile={openFile}
          processFile={processFile}
          loadSample={loadSample}
          recentlyOpened={recentlyOpened}
          askPermissionCallback={askPermissionCallback}
          isApp={isApp}
        />
      </Allotment>
    </div>
  );
}

function Table({ columns: userColumns, data, setSearch, hh, refreshing, setRefreshing }) {
  const filterTypes = React.useMemo(
    () => ({
      // // Add a new fuzzyTextFilterFn filter type.
      // fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { filters, expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
      defaultColumn,
      filterTypes,
      manualFilters: true,
      autoResetExpanded: false,
      initialState: useMemo(() => ({
        expanded: {
          '0': true,
          '1': false,
          '2': true,
          '3': false,
        }
      }), [])
    },
    useFlexLayout,
    useExpanded,
    useFilters,
  )

  useEffect(() => {
    setSearch(filters?.[0]?.value ?? '')
  }, [filters, setSearch])

  return <>
    <div className="table" {...getTableProps({ style: { flex: 1, overflow: 'auto', textAlign: 'left', overscrollBehavior: 'none' } })}>
      <div style={{ position: 'sticky', top: 0, left: 0, background: 'var(--thead-background)' }}>
        {headerGroups.map(headerGroup => (
          <div className="tr" {...headerGroup.getHeaderGroupProps({
            style: { height: hh, borderBottom: '1px solid var(--lightgray)' },
          })}>
            {headerGroup.headers.map((column, i) => (
              <div className="th" {...column.getHeaderProps()}>
                {column.render('Header', { refreshing, setRefreshing })}
                {column.canFilter ? column.render('Filter') : null}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <div className="tr" {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <div className="td" {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </div>
              })}
            </div>
          )
        })}
      </div>
    </div>
    {/* {vscode ? null : <div style={{ borderTop: '1px solid var(--lightgray)', background: 'var(--thead-background)', height: hh }}></div>} */}
  </>
}

export default App;

export function range(start, end) {
  return Array.from({ length: end - start }, (_, i) => i)
}

export function* zip2(xs, ys) {
  const xit = xs[Symbol.iterator]();
  const yit = ys[Symbol.iterator]();
  while (true) {
    const [xr, yr] = [xit.next(), yit.next()];
    if (xr.done || yr.done) break;
    yield [xr.value, yr.value];
  }
}

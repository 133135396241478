// import React, { forwardRef } from "react";
// import { Link } from "react-router-dom";
import { ThemeProvider } from 'styled-components';

import * as r95 from '@react95/core/esm';
import { ModalProvider } from '@react95/core/esm/Modal'
import BaseTheme, { generateShadows } from '@react95/core/esm/ThemeProvider/themes/baseTheme';

import { LinkButton as R95LinkButton } from "./LinkButton";

const colors = {
  anchor: 'var(--blue)',
  anchorVisited: 'var(--purple)',
  borderDark: 'var(--borderDark)',
  borderDarkest: 'var(--borderDarkest)',
  borderLight: 'var(--borderLight)',
  borderLighter: 'var(--borderLighter)',
  borderLightest: 'var(--borderLightest)',
  canvas: 'var(--color)',
  canvasText: 'var(--background)',
  canvasTextDisabled: '#848584',
  canvasTextDisabledShadow: '#fefefe',
  canvasTextInvert: '#fefefe',
  headerBackground: '#000e7a',
  headerNotActiveBackground: '#7f787f',
  headerNotActiveText: '#c6c6c6',
  headerText: '#fefefe',
  material: 'var(--gray5)',
  materialDark: '#9a9e9c',
  materialText: 'var(--color)',
  materialTextDisabled: '#868a8e',
  materialTextDisabledShadow: '#d2d2d2',
  materialTextInvert: 'var(--background)',
  progress: '#000e7a',
  inputBackground: 'var(--background)',
  inputBackgroundDisabled: 'var(--gray6)',
  tooltip: '#fefbcc',
};

const theme = {
  ...BaseTheme,
  colors,
  shadows: generateShadows(colors),
};

const R95ThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    <ModalProvider>{children}</ModalProvider>
  </ThemeProvider>
);

export { R95ThemeProvider as ThemeProvider }

export const Input = r95.Input

export const TextArea = r95.TextArea

export const Button = r95.Button

export const Fieldset = r95.Fieldset;

export const LinkButton = R95LinkButton;

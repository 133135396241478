import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { default as useResizeObserver } from '@react-hook/resize-observer'
import { useLocation } from 'react-router';
import { default as storage } from 'kv-storage-polyfill/src/index';

function withSettled(p) {
  p.settled = false;
  p.finally(() => { p.settled = true })
  return p;
}

/**
 * @param {() => Promise<void>} effect 
 * @param {DependencyList} deps 
 */
export function useAsyncEffect(effect, deps) {
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const task = withSettled(effect({ signal }).catch(_ => {
      if (_ instanceof Error && _.message === 'AbortError') {}
      else throw _;
    }));
    return () => {
      if (!task.settled) {
        controller.abort();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function usePrevious(value) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useContentRect(target) {
  const [contentRect, setContentRect] = useState()

  useLayoutEffect(() => {
    setContentRect(target.current.getBoundingClientRect())
  }, [target])

  // Where the magic happens
  useResizeObserver(target, _ => setContentRect(_.contentRect))
  return contentRect
}

export function useURLSearchParams() { 
  return new URLSearchParams(useLocation().search)
}

export function useQuery() { 
  return Object.fromEntries(new URLSearchParams(useLocation().search))
}

export function useStorageArea(key, fallback, { area = storage } = {}) {
  const [value, setV] = useState(fallback);

  useAsyncEffect(async ({ signal }) => {
    const nv = await area.get(key);
    if (!signal.aborted) setV(nv);
  }, [area, key])

  const setValue = useCallback(async nv => {
    await area.set(key, nv);
    setV(nv);
  }, [area, key])

  return [value, setValue]
}

export function useLaunchQueueFiles() {
  const [params, setParams] = useState(null);
  useEffect(() => {
    if ('launchQueue' in window) {
      window.launchQueue.setConsumer(launchParams => {
        if (!launchParams.files.length) return;
        setParams(launchParams.files)
      });
    }
  }, [])
  return params;
}

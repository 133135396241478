/* eslint-disable no-restricted-globals */
import { UUID } from 'uuid-class';
import { ExtendablePromise } from './promise-group';

declare global {
  interface Crypto {
    randomUUID(): string;
  }
}

type Awaitable<T> = T | Promise<T>;

interface PostMessageOptions {
    transfer?: any[];
}

const $message = 'post-message-x.message';
const $abort = 'post-message-x.abort';
const $response = 'post-message-x.response';

export interface PostMessageOptionsX {
  transfer?: Transferable[],
  signal?: AbortSignal,
}

const hasSharedWorker = typeof SharedWorker !== 'undefined';

export function postMessage<Q = any, R = any>(worker: Awaitable<Worker|SharedWorker>, message: Q, transfer: Transferable[]): Promise<R>;
export function postMessage<Q = any, R = any>(worker: Awaitable<Worker|SharedWorker>, message: Q, options: PostMessageOptionsX): Promise<R>;
export function postMessage<Q = any, R = any>(
  awaitableWorker: Awaitable<Worker|SharedWorker>,
  message: Q,
  opts: Transferable[] | PostMessageOptionsX = {}
) {
  return new Promise<R>(async (res, rej) => {
    const { transfer = [], signal } = Array.isArray(opts) ? { transfer: opts, signal: undefined } : opts;
    const worker = await awaitableWorker;
    const port = (hasSharedWorker && worker instanceof SharedWorker) ? worker.port : worker as Worker;

    if (signal?.aborted) {
      rej(new DOMException('AbortError'));
    } else {
      const id = 'randomUUID' in globalThis.crypto ? crypto.randomUUID() : UUID.v4().toString();

      port.addEventListener('message', function listener(event) {
        const ev = event as MessageEvent<{ kind: string, id: string, result: R, error?: any }>;
        if (ev.data.kind === $response && ev.data?.id === id) {
          port.removeEventListener('message', listener);

          const { data } = ev;
          if (signal?.aborted) {
            rej(new DOMException('AbortError'))
          } else if (data.error != null) {
            // Convert to an Error instance so it's formatted correctly in console/logs:
            rej(typeof data.error === 'object'
              ? Object.assign(Error(), data.error)
              : Error(data.error));
          } else {
            res(data.result);
          }
        }
      });

      signal?.addEventListener('abort', () => port.postMessage({ kind: $abort, id }))
      port.postMessage({ kind: $message, id, message }, transfer);
    }
  });
}

const ctrlMap = new Map<string, AbortController>();
const callMap = new Map<MessageEventX, boolean>();

globalThis.addEventListener('message', (event: MessageEvent<{ kind: string, id: string, message: any }>) => {
  if (typeof event.data !== 'object') return;

  const { kind, id, message } = event.data || {};
  if (id != null) {
    if (kind === $message) {
      let controller;
      ctrlMap.set(id, controller = new AbortController())
      const { signal } = controller;

      const promise = new ExtendablePromise<void>();
      const ev = new MessageEventXImpl(id, signal, message, promise);
      callMap.set(ev, false);

      globalThis.dispatchEvent(ev);

      promise.finally(() => {
        if (callMap.get(ev) === false) {
          globalThis.postMessage({ kind: $response, id })
        }
        callMap.delete(ev);
        ctrlMap.delete(id)
      });
    } else if (kind === $abort) {
      ctrlMap.get(id)?.abort();
    }
  }
})

export interface RemoteMessage<T> {
  readonly data: T;
  readonly signal: AbortSignal;
}

export class Message<T = any> {
  #data: T;
  #options?: (Transferable[] | PostMessageOptions);
  constructor(data: T, opt: Transferable[]);
  constructor(data: T, opt?: PostMessageOptions);
  constructor(data: T, opt?: (Transferable[] | PostMessageOptions)) {
    this.#data = data;
    this.#options = opt;
  }
  get data() {
    return this.#data;
  }
  get transfer(): Transferable[] {
    return Array.isArray(this.#options) ? this.#options : this.#options?.transfer ?? [];
  }
}

class MessageEventXImpl<T = any> extends Event implements MessageEventX<T> {
  #id: string;
  #message: RemoteMessage<T>
  #promise: ExtendablePromise<void>

  // constructor(type: string, eventInitDict?: FetchEventInit);
  constructor(id: string, signal: AbortSignal, data: T, promise: ExtendablePromise<void>) {
    super('message-x');
    this.#id = id;
    this.#message = {
      get data() { return data },
      get signal() { return signal },
    }
    this.#promise = promise;
  }

  get message() {
    return this.#message;
  }

  respondWith(msg: Awaitable<T | Message<T>>): void {
    callMap.set(this, true);
    this.#promise.waitUntil((async () => {
      const id = this.#id;
      try {
        const _ = await msg;
        if (_ instanceof Message) {
          globalThis.postMessage({ kind: $response, id, result: _.data }, _.transfer);
        } else {
          globalThis.postMessage({ kind: $response, id, result: _ });
        }
      } catch (_) {
        // if (_ instanceof Error && _.message !== 'AbortError') console.warn(_)
        globalThis.postMessage({ kind: $response, id, error: clone(_) });
      }
    })());
  }

  waitUntil(_f: any): void {
    this.#promise.waitUntil(_f);
  }
}

// https://github.com/vishwam/worker-async/blob/master/src/messageHandler.ts
function clone(obj: any, clonedObjs = new Map()) {
  switch (typeof obj) {
    case 'bigint':
    case 'boolean':
    case 'number':
    case 'string':
      return obj;
    case 'object': {
      if (obj === null) {
        return obj;
      }

      // check if we've already cloned this object (circular dependency):
      let result = clonedObjs.get(obj);
      if (result === undefined) {
        // create a new clone object:
        result = {};
        clonedObjs.set(obj, result);

        let proto = obj;
        do {
          for (const name of Object.getOwnPropertyNames(proto)) {
            if (!(name in result)) {
              result[name] = clone(obj[name], clonedObjs);
            }
          }

          proto = Object.getPrototypeOf(proto); // climb up the prototype chain
        } while (proto != null);
      }

      return result;
    }
    default: break;
  }
}

//#region Global Types
declare global {
  interface ExtendableEvent extends Event {
    waitUntil(f: any): void;
  }

  interface ExtendableEventInit extends EventInit {
  }

  var ExtendableEvent: {
    prototype: ExtendableEvent;
    new(type: string, eventInitDict?: ExtendableEventInit): ExtendableEvent;
  };

  // interface MessageEventXInit<T = any> extends ExtendableEventInit {
  //   readonly message: RemoteMessage<T>;
  // }

  // var MessageEventX: {
  //   prototype: MessageEventX;
  //   new(type: string, eventInitDict: MessageEventXInit): MessageEventX;
  // };

  interface MessageEventX<T = any> extends ExtendableEvent {
    readonly message: RemoteMessage<T>;
    respondWith(r: Awaitable<T | Message<T>>): void;
  }

  // interface Window {
  //   MessageEventX: new (type: string, eventInitDict: MessageEventXInit) => MessageEventX;
  // }

  function addEventListener(type: 'message-x', handler: (event: MessageEventX) => void): void;
}
//#endregion
